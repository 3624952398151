<template>
  <div class="row">
    <div class="col-12">
      <Breadcrumb :items="breadcrumbs" />
      <div class="lk">
        <div class="row">
          <div class="col-lg-3">
            <div class="lk-nav">
              <UserBar />
              <SideBar />
            </div>
          </div>
          <div class="col-lg-9">
            <div class="catalog__content" >
              <div class="catalog__head mb-0">
                <div class="catalog__title title title--secondary">
                  Просмотренные товары
                </div>
              </div>
              <div class="row goods-grid goods-grid--lk">
                <div class="col-md-4 col-6 goods-grid__item">
                  <Item />
                </div>
              </div>
              <button class="btn btn--show-more btn--top-indent">
                <svg class="icon-svg btn--show-more__icon"><use xlink:href="@/assets/img/sprite.svg#load-more"></use></svg>
                Посмотреть еще
              </button>
              <!--<b-pagination
                v-model="currentPage"
                align="center"
                :total-rows="rows"
                :per-page="perPage"
                :ellipsis-class="[pageClass, pageClassEllipsis]"
                :page-class="pageClass"
                :next-class="[pageClass, pageClassArrow]"
                :prev-class="[pageClass, pageClassArrow]"
                first-class="d-none"
                last-class="d-none"
              >
                <template #next-text>
                  <svg width="8" height="15" class="icon-svg" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.18758 1.25L7.41675 7.47917L1.18758 13.7083" stroke="currentColor" stroke-width="0.958333" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </template>
                <template #prev-text>
                  <svg width="8" height="15" class="icon-svg" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.43742 1.25L1.20825 7.47917L7.43742 13.7083" stroke="currentColor" stroke-width="0.958333" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </template>
              </b-pagination>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Callback />
</template>
<script>
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import Callback from '~/pages/account/partials/callback'
import UserBar from '~/pages/account/partials/userBar'
import SideBar from '~/pages/account/partials/sidebar'
import Item from './partials/item'
export default {
  components: {
    Callback,
    UserBar,
    SideBar,
    Item
  },
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('viewed')
    })
    return {
      t,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('viewed'), href: null, active: true }
      ]
    }
  }
}
</script>
